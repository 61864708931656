@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;700;800&display=swap");

.question-container {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  font-family: "Plus Jakarta Sans", sans-serif;
  background-color: rgba(237, 237, 237, 1);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  min-height: 500px;
}

.question-content {
  max-height: 60vh;
  overflow-y: scroll;
}

.question-content::-webkit-scrollbar {
  display: none;
}

.content {
  margin-left: 65px;
  margin-right: 65px;
  text-align: left;
  padding-bottom: 20px;
}

.content h4 {
  margin-top: 46px;
}

.content p {
  margin-top: 65px;
}

.content-buttons {
  text-align: right;
  margin-right: 65px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.content-buttons button {
  background-color: #ff6e31;
}

.choices {
  font-family: "Plus Jakarta Sans", sans-serif;
  margin-bottom: 10px;
}
