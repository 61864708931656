@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;700;800&display=swap");

.sideBar {
  background-color: #faab78;
  width: 300px;
  height: 100vh;
  text-align: center;
}

.sidebar-list h5 {
  color: white;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.sidebar-list h3 {
  color: white;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.sidebar-list {
  margin-top: 146px;
  margin-right: 10px;
  padding-left: 32px;
  text-align: left;
}
